




































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const ChatModule = namespace("ChatModule");
const Auth = namespace("Auth");
import MessageService from "@/services/MessageService";
import { ChatDto } from "@/dtos/chat.dto";
import ChatService from "@/services/ChatService";

@Component
export default class Chat extends Vue {
  private loading = false;
  private chatId = '';
  private message = '';

  private chat: ChatDto = {
    id: '',
    initiatorId: '',
    offerId: '',
    offerUserId:'',
    offerName: '',
    image: '',
  }

  private intervalId: number|undefined = undefined;

  private messages: any[] = [];

  @Auth.State("user")
  private currentUser!: any;

  async sendMessage() {
    await MessageService.sendMessage(this.chatId, this.currentUser.id, this.message);
    await this.fetchNewMessages();
    this.message = '';
  }

  async mounted() {
    this.chatId = this.$route.params.id;
    this.chat = await ChatService.getChat(this.chatId);
    await this.loadMessages();
    this.pullMessages();
  }

  async beforeDestroy() {
    clearInterval(this.intervalId);
  }

  async pullMessages() {
    const intervalId = setInterval(function(self: any) {
      self.fetchNewMessages();
    }, 3000, this);

    if(this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = intervalId;
  }

  async fetchNewMessages() {
    const lastMessage = this.messages[this.messages.length - 1];
    const newMessages = await MessageService.getMessages(this.chatId, lastMessage?.createdAt);
    newMessages.forEach((message: any) => {
      if(message.id !== lastMessage?.id) {
        this.messages.push(message);
      }
    });
    if(newMessages.length > 1) {
      await MessageService.confirmReceived(this.chatId, this.currentUser.id);
    }
  }

  async loadMessages(createdFrom?: string){
    this.messages = await MessageService.getMessages(this.chatId, createdFrom);
    await MessageService.confirmReceived(this.chatId, this.currentUser.id);
  }

}
