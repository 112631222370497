import { apiConfig } from '@/config/api.config';
import axios from 'axios';
import authHeader from './auth-header';
import { addFilter } from './filter-helper';

const API_URL = `${apiConfig.host}api/chats/`;

class ChatService {
  async getMessages(chatId: string, createdFrom?: string) {
    let url = `${API_URL}${chatId}/messages/`;

    if (createdFrom) {
      url = addFilter(url, 'createdFrom', createdFrom);
    }

    const response = await axios.get(url, { headers: authHeader() });
    return response.data;
  }

  async sendMessage(chatId: string, senderId: string, text: string) {
    const url = `${API_URL}${chatId}/messages`;
    const response = await axios.post(
      url,
      { senderId, text },
      { headers: authHeader() },
    );
    return response.data;
  }

  async confirmReceived(chatId: string, userId: string) {
    const url = `${API_URL}${chatId}/received/${userId}`;
    const response = await axios.post(url, {}, { headers: authHeader() });
    return response.data;
  }
}

export default new ChatService();
